import React from "react";

import "../../index.css";
import "./CurriculumBtn.css";

import curriculum from "../../assets/icons/curriculum.png";

export default function CurriculumBtn() {
  return (
    <div
      id="curriculumBtn"
      onClick={() => window.open("https://www.emmegi.dev/goldin-matteo-cv.pdf")}
    >
      <img alt="myCV" src={curriculum}></img>
    </div>
  );
}
