import React from "react";
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";

import "./Form.css";
import "../../index.css";

import location from "../../assets/icons/location-dot-solid.svg";
import phone from "../../assets/icons/phone-solid.svg";
import at from "../../assets/icons/at-solid.svg";

import spinner from "../../assets/icons/spinner-solid.svg";

import success from "../../assets/icons/circle-check-regular.svg";
import error from "../../assets/icons/circle-exclamation-solid.svg";
import warning from "../../assets/icons/triangle-exclamation-solid.svg";

export default function Form() {
  const { t, i18n } = useTranslation();

  const [name, setName] = useState(null);
  const [email, setEmail] = useState(null);
  const [message, setMessage] = useState(null);

  const [errMessage, setErrMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const nameRef = useRef();
  const emailRef = useRef();
  const messageRef = useRef();

  const validateEmail = (email) => {
    if (email === "") return true;
    return /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(email);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name === "" ||
      name === null ||
      email === "" ||
      email === null ||
      message === "" ||
      message === null
    ) {
      setErrMessage(t("fillAllFields"));
      return;
    }

    if (!validateEmail(email)) {
      setErrMessage(t("invalidEmail"));
      return;
    }
    sendEmail();
  };

  const sendEmail = async () => {
    setIsLoading(true);
    try {
      const response = await axios.post(
        "https://www.emmegi.dev/api/mail",
        JSON.stringify({
          name: name,
          email: email,
          message: message,
        }),
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "https://www.emmegi.dev/",
            Referer: "https://www.emmegi.dev/",
          },
        }
      );
      if (response.status === 200) {
        setSuccessMessage(t("sentSuccess"));
      } else {
        setErrMessage(t("networkError"));
      }

      setErrMessage(null);
      setName(null);
      setEmail(null);
      setMessage(null);
    } catch {
      setErrMessage(t("networkError"));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div class="contact__form">
      <h1 class="tb upper bold">{t("contactMe")}</h1>
      <div class="contact__info">
        <img class="icon" src={location} alt="" />
        <h1 class="tm">{t("location")}</h1>
      </div>
      <div class="contact__info">
        <img class="icon" src={phone} alt="" />
        <h1 class="tm">+39 371 415 7051</h1>
      </div>
      <div class="contact__info">
        <img class="icon" src={at} alt="" />
        <h1 class="tm">0matteo.goldin6@gmail.com</h1>
      </div>

      <div class="separator">
        <hr />
        <h1 className="tm upper bold">{t("or")}</h1>
        <hr />
      </div>
      <div class="input-field">
        <input
          onChange={(e) => {
            setErrMessage(null);
            setName(e.target.value);
          }}
          ref={nameRef}
          required
          type="text"
        />
        <label className="tm">{t("nameLabel")}</label>
      </div>

      <div class="input-field">
        <input
          onChange={(e) => {
            setErrMessage(null);
            setEmail(e.target.value);
          }}
          ref={emailRef}
          required
          type="text"
        ></input>
        <label className="tm">{t("emailLabel")}</label>
      </div>

      <div class="input-field">
        <textarea
          onChange={(e) => {
            setErrMessage(null);
            setMessage(e.target.value);
          }}
          required
          rows="3"
        ></textarea>
        <label className="tm">{t("messageLabel")}</label>
      </div>
      {errMessage != null ? (
        <div className="status">
          <img src={warning} className="icon invert" alt=""></img>
          <h1 className="ts">{errMessage}</h1>
        </div>
      ) : null}
      {successMessage != null ? (
        <div className="status">
          <img src={success} className="icon invert" alt=""></img>
          <h1 className="ts">{successMessage}</h1>
        </div>
      ) : null}
      <a onClick={handleSubmit}>
        {isLoading ? (
          <img className="icon spin invert" src={spinner} alt=""></img>
        ) : null}
        {isLoading ? t("loading") : t("submit")}
      </a>
    </div>
  );
}
