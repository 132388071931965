import React from "react";
import { useTranslation } from "react-i18next";

import "./Portfolio.css";
import "../../index.css";

import pioveopicina from "./../../assets/pioveopicina.png";
import stirificio from "./../../assets/stirificio.png";
import khronos from "./../../assets/khronos.png";

import flutter from "./../../assets/brands/flutter.svg";
import react from "./../../assets/brands/react.svg";
import nginx from "./../../assets/brands/nginx.svg";
import python from "./../../assets/brands/python.svg";
import java from "./../../assets/brands/java.svg";
import springBoot from "./../../assets/brands/spring-boot.svg";
import git from "./../../assets/brands/git.svg";
import html5 from "./../../assets/brands/html5.svg";
import css3 from "./../../assets/brands/css3.svg";
import javascript from "./../../assets/brands/javascript.svg";
import ubuntu from "./../../assets/brands/ubuntu.svg";
import mysql from "./../../assets/brands/mysql.svg";

export default function Portfolio() {
  const { t, i18n } = useTranslation();
  return (
    <section id="portfolio">
      <div className="portfolio__content skills">
        <div className="skills__grid">
          <div className="skill">
            <div className="skill__icon">
              <img src={flutter} alt="Flutter" />
            </div>
            <div className="skill__title">
              <p>Flutter</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={react} alt="React" />
            </div>
            <div className="skill__title">
              <p>React</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={nginx} alt="Nginx" />
            </div>
            <div className="skill__title">
              <p>Nginx</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={python} alt="Python" />
            </div>
            <div className="skill__title">
              <p>Python</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={java} alt="Java" />
            </div>
            <div className="skill__title">
              <p>Java</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={springBoot} alt="Spring Boot" />
            </div>
            <div className="skill__title">
              <p>Spring Boot</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={git} alt="Git" />
            </div>
            <div className="skill__title">
              <p>Git</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={html5} alt="HTML" />
            </div>
            <div className="skill__title">
              <p>HTML</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={css3} alt="CSS" />
            </div>
            <div className="skill__title">
              <p>CSS</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={javascript} alt="JavaScript" />
            </div>
            <div className="skill__title">
              <p>JavaScript</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={ubuntu} alt="Ubuntu" />
            </div>
            <div className="skill__title">
              <p>Ubuntu</p>
            </div>
          </div>
          <div className="skill">
            <div className="skill__icon">
              <img src={mysql} alt="MySQL" />
            </div>
            <div className="skill__title">
              <p>MySQL</p>
            </div>
          </div>
        </div>
      </div>
      <div className="portfolio__content projects">
        <div
          onClick={() =>
            window.open("https://www.pioveopicina.it", "_blank", "noopener,")
          }
          className="project pioveopicina"
        >
          <img alt="pioveopicina website" src={pioveopicina}></img>
          <p className="image__text">{t("pioveDesc")}</p>
        </div>
        <div
          onClick={() =>
            window.open("https://www.stirificio.it", "_blank", "noopener,")
          }
          className="project stirificio"
        >
          <img alt="stirificio website" src={stirificio}></img>
          <p className="image__text">{t("stirificioDesc")}</p>
        </div>
        <div className="project khronos">
          <img src={khronos} alt="khronos logo" />
          <p className="image__text">{t("khronosDesc")}</p>
        </div>
      </div>
    </section>
  );
}
