import React from "react";

import "./Socials.css";
import "../../index.css";

import github from "../../assets/icons/github-brands-solid.svg";
import linkedin from "../../assets/icons/linkedin-brands.svg";
import telegram from "../../assets/icons/telegram-brands-solid.svg";
import whatsapp from "../../assets/icons/whatsapp-brands-solid.svg";

export default function Socials() {
  return (
    <div class="socials">
      <img
        onClick={() =>
          window.open(
            "https://github.com/Bl4ckDrake",
            "_blank",
            "noopener,noreferrer"
          )
        }
        class="icon"
        src={github}
        alt=""
      />
      <img
        onClick={() =>
          window.open(
            "https://www.linkedin.com/in/matteo-goldin-911608282",
            "_blank",
            "noopener,noreferrer"
          )
        }
        class="icon"
        src={linkedin}
        alt=""
      />
      <img
        onClick={() =>
          window.open(
            "https://t.me/Bl4ckDrake",
            "_blank",
            "noopener,noreferrer"
          )
        }
        class="icon"
        src={telegram}
        alt=""
      />
      <img
        onClick={() =>
          window.open(
            "https://wa.me/+393714157051",
            "_blank",
            "noopener,noreferrer"
          )
        }
        class="icon"
        src={whatsapp}
        alt=""
      />
    </div>
  );
}
